import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

const baseApiUrl = process.env.VUE_APP_API_BASE_URL;
// Cache the token with expiration time to reduce unnecessary token requests
let authTokenCache = {
  token: null,
  expiration: null
};

// Funkcia pre úpravu Fetch požiadavky
const fetchRequest = async (url, options = {}) => {
  try {
    // Priraďte plnu URL priamo tu
    url = `${baseApiUrl}${url}`;
    
    // Setup default options if not provided
    options.headers = options.headers || {};
    options.method = options.method || 'GET';

    // Check if current user exists
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      throw new Error('User not authenticated');
    }

    // Check if we have a cached valid token
    const now = Date.now();
    if (!authTokenCache.token || !authTokenCache.expiration || now >= authTokenCache.expiration) {
      // Get a new token
      authTokenCache.token = await currentUser.getIdToken(true); // Force refresh
      // Set expiration to 55 minutes (tokens typically last 60 minutes)
      authTokenCache.expiration = now + (55 * 60 * 1000);
    }

    // Set the authorization header with the token
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${authTokenCache.token}`,
      Accept: '*/*',
    };

    // Make the fetch request
    const response = await fetch(url, options);
    
    // If the response indicates an authentication issue, try to refresh the token once
    if (response.status === 401) {
      console.log('Authentication token expired, refreshing...');
      // Clear the cache and force a new token
      authTokenCache.token = null;
      authTokenCache.expiration = null;
      
      // Get a fresh token
      const freshToken = await currentUser.getIdToken(true);
      authTokenCache.token = freshToken;
      authTokenCache.expiration = Date.now() + (55 * 60 * 1000);
      
      // Retry the request with the new token
      options.headers.Authorization = `Bearer ${freshToken}`;
      return fetch(url, options);
    }
    
    return response;
  } catch (error) {
    console.error('Fetch request error:', error.message);
    // Rethrow the error to be handled by the caller
    throw error;
  }
};

export default fetchRequest;